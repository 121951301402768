/* eslint-disable */
.lf-player-container > .lf-player-controls {
  height: 45px !important;
  width: 85%;
  background-color: rgb(33, 69, 105) !important;

  @media screen and (max-width: 1024px) {
    width: 75%;
  }
}
